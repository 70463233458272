body {
    font-size: 17px;
}

p,
a {
    padding-top: 7px;
    padding-bottom: 7px;
}

li {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
}

ol,
ul {
    padding-left: 2rem;
    padding-bottom: 14px;
}