.bg-white-round {
    background-color: #fffa;
    padding: 2px;
    border-radius: 5px;
}


/* width */

 ::-webkit-scrollbar {
    width: 8px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #0000;
    border-radius: 4px;
    margin: 5px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #ac5eff3f;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background:#ac5eff; 
  }